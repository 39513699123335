@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300&display=swap');

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Public Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.img-gradient.img{
  max-width: 100%;
}
.img-gradient::after{
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 100%);
  margin-top: -180px;
  height: 180px;
  width: 100%;
  content: '';
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.modal-itemDetails{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  /* height: 70%; */
  max-height: 70%;
  min-height: 50%;
  background-color: white;
  border-radius: 6px;
  padding: 2ch;
  overflow: auto
}
.navigationChip {
  padding: 15px !important;
  margin: 5px !important;
  cursor: pointer !important;
  background-color: #021d32 !important;
  color: #61dafb !important;
  font-size: 15px !important;
}
.landingBackground {
  background-image: url('assets/images/dots.png');
}

.landingIcon {
  color: "#021d32";
                        font-size: 30px;
                        padding: 15px
}

